import React from "react";

import productImg1 from "../../../../assets/images/products/product-image-1.jpg";
import productImg2 from "../../../../assets/images/products/product-image-2.jpg";
import productImg3 from "../../../../assets/images/products/product-image-3.jpg";
import productImg4 from "../../../../assets/images/products/product-image-4.jpg";
import productImg5 from "../../../../assets/images/products/product-image-5.jpg";
import productImg6 from "../../../../assets/images/products/product-image-6.jpg";
import productImg7 from "../../../../assets/images/products/product-image-7.jpg";
import productImg8 from "../../../../assets/images/products/product-image-8.jpg";
import productImg9 from "../../../../assets/images/products/product-image-9.jpg";
import productImg10 from "../../../../assets/images/products/product-image-10.jpg";
import productImg11 from "../../../../assets/images/products/product-image-11.jpg";
import productImg12 from "../../../../assets/images/products/product-image-12.jpg";

import { BsFillEyeFill, BsFillHeartFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";


function ShopProducts(props) {
  return (
    <>
      <div className={`tm-shop-products ${props.isClass ? "list-view": ""}`}>
        <div className="row mt-30-reverse">
          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg1}
                    alt="product image"
                  />
                  <img
                    src={productImg2}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg3}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg4}
                    alt="product image"
                  />
                  <img
                    src={productImg5}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-soldout">Sold out</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg6}
                    alt="product image"
                  />
                  <img
                    src={productImg7}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg8}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg9}
                    alt="product image"
                  />
                  <img
                    src={productImg10}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-soldout">Sold out</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg11}
                    alt="product image"
                  />
                  <img
                    src={productImg12}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg3}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg4}
                    alt="product image"
                  />
                  <img
                    src={productImg5}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-soldout">Sold out</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg7}
                    alt="product image"
                  />
                  <img
                    src={productImg8}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg8}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}

          {/* Single Product */}
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-50">
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img
                    src={productImg1}
                    alt="product image"
                  />
                  <img
                    src={productImg2}
                    alt="product image"
                  />
                </div>
                <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <FaShoppingCart /> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <BsFillEyeFill />
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <BsFillHeartFill />
                      </a>
                    </li>
                  </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-soldout">Sold out</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span className="is-active">
                      <FaRegStar />
                    </span>
                    <span>
                      <FaRegStar />
                    </span>
                  </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Single Product */}
        </div>
      </div>
    </>
  );
}

export default ShopProducts;
