import React from "react";
import Slider from "react-slick";
import productImg1 from "../../../assets/images/products/product-image-1.jpg";
import productImg2 from "../../../assets/images/products/product-image-2.jpg";
import productImg3 from "../../../assets/images/products/product-image-3.jpg";
import productImg4 from "../../../assets/images/products/product-image-4.jpg";
import productImg5 from "../../../assets/images/products/product-image-5.jpg";

import "./SimilarProducts.css";
function SimilarProducts() {
    
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        autoplay: true,
        
      };
  return (
    <>
      <div className="tm-similliar-products tm-padding-section-sm-top">
        <h4 className="small-title">Similliar Products</h4>
        <Slider  className="row tm-products-slider3" {...settings}>
          {/* Single Product */}
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img src={productImg1} alt="product image" />
                  <img src={productImg2} alt="product image" />
                </div>
                <ul className="tm-product-actions">
                  <li>
                    <a href="#">
                      <i className="ion-android-cart"></i> Add to cart
                    </a>
                  </li>
                  <li>
                    <button data-fancybox data-src="#tm-product-quickview">
                      <i className="ion-eye"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-heart"></i>
                    </a>
                  </li>
                </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span>
                    <i className="ion-android-star-outline"></i>
                  </span>
                </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* Single Product */}

          {/* Single Product */}
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img src={productImg3} alt="product image" />
                </div>
                <ul className="tm-product-actions">
                  <li>
                    <a href="#">
                      <i className="ion-android-cart"></i> Add to cart
                    </a>
                  </li>
                  <li>
                    <button data-fancybox data-src="#tm-product-quickview">
                      <i className="ion-eye"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-heart"></i>
                    </a>
                  </li>
                </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span>
                    <i className="ion-android-star-outline"></i>
                  </span>
                </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* Single Product */}

          {/* Single Product */}
          
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img src={productImg4} alt="product image" />
                  <img src={productImg5} alt="product image" />
                </div>
                <ul className="tm-product-actions">
                  <li>
                    <a href="#">
                      <i className="ion-android-cart"></i> Add to cart
                    </a>
                  </li>
                  <li>
                    <button data-fancybox data-src="#tm-product-quickview">
                      <i className="ion-eye"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-heart"></i>
                    </a>
                  </li>
                </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-soldout">Sold out</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span>
                    <i className="ion-android-star-outline"></i>
                  </span>
                </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* Single Product */}

          {/* Single Product */}
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img src={productImg1} alt="product image" />
                  <img src={productImg2} alt="product image" />
                </div>
                <ul className="tm-product-actions">
                  <li>
                    <a href="#">
                      <i className="ion-android-cart"></i> Add to cart
                    </a>
                  </li>
                  <li>
                    <button data-fancybox data-src="#tm-product-quickview">
                      <i className="ion-eye"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-heart"></i>
                    </a>
                  </li>
                </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-new">New</span>
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span>
                    <i className="ion-android-star-outline"></i>
                  </span>
                </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* Single Product */}

          {/* Single Product */}
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img src={productImg3} alt="product image" />
                </div>
                <ul className="tm-product-actions">
                  <li>
                    <a href="#">
                      <i className="ion-android-cart"></i> Add to cart
                    </a>
                  </li>
                  <li>
                    <button data-fancybox data-src="#tm-product-quickview">
                      <i className="ion-eye"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-heart"></i>
                    </a>
                  </li>
                </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-sale">Sale</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span>
                    <i className="ion-android-star-outline"></i>
                  </span>
                </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* Single Product */}

          {/* Single Product */}
            <div className="tm-product">
              <div className="tm-product-topside">
                <div className="tm-product-images">
                  <img src={productImg4} alt="product image" />
                  <img src={productImg5} alt="product image" />
                </div>
                <ul className="tm-product-actions">
                  <li>
                    <a href="#">
                      <i className="ion-android-cart"></i> Add to cart
                    </a>
                  </li>
                  <li>
                    <button data-fancybox data-src="#tm-product-quickview">
                      <i className="ion-eye"></i>
                    </button>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-heart"></i>
                    </a>
                  </li>
                </ul>
                <div className="tm-product-badges">
                  <span className="tm-product-badges-soldout">Sold out</span>
                </div>
              </div>
              <div className="tm-product-bottomside">
                <h6 className="tm-product-title">
                  <a href="product-details.html">Stylist daimond earring</a>
                </h6>
                <div className="tm-ratingbox">
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span className="is-active">
                    <i className="ion-android-star-outline"></i>
                  </span>
                  <span>
                    <i className="ion-android-star-outline"></i>
                  </span>
                </div>
                <span className="tm-product-price">$29.99</span>
                <div className="tm-product-content">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the when an unknown printer
                    took a galley of type and scrambled it to make a type
                    specimen book. It has survived not only five centuries, but
                    also the leap into electronic typesetting.
                  </p>
                  <ul className="tm-product-actions">
                    <li>
                      <a href="#">
                        <i className="ion-android-cart"></i> Add to cart
                      </a>
                    </li>
                    <li>
                      <button data-fancybox data-src="#tm-product-quickview">
                        <i className="ion-eye"></i>
                      </button>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ion-heart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          {/* Single Product */}
        </Slider>
      </div>
    </>
  );
}

export default SimilarProducts;
