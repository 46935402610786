import React from "react";
import OfferCountDown from "./offer-countdown/OfferCountDown";
import OfferFigure from "./offer-figure/OfferFigure";

function Offer() {
  return (
    <>
      {/* Offer Area */}
      <div className="tm-section tm-offer-area tm-padding-section bg-grey">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 order-2 order-lg-1">
              <div className="tm-offer-content">
                <h6>Super deal of the Month</h6>
                <h1>
                  Brand ear ring on <span>$250</span> only
                </h1>
                <OfferCountDown />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-2">
              <OfferFigure />
            </div>
          </div>
        </div>
      </div>
      {/* Offer Area */}
    </>
  );
}

export default Offer;
